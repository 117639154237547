import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import React, { Suspense, useEffect, useState } from 'react';
import { Route } from 'react-router';
import { withUAL } from 'ual-reactjs-renderer';

import { getAssetMintNumber, getAssetsData, getHistory } from '@api/api';
import StyledButton from '@components/Button';
import StyledErrorModal from '@components/ErrorModal';
import Loading from '@components/Loading';
import ViewAssetsFromRips from '@components/ViewRips';
import WithRouter from '@components/WithRouter';
import { IPFS_ENDPOINT, SHOW_HEADER } from '@utils/globals';
import ROUTES from '@utils/routes';
import STRINGS from '@utils/strings';

const Grid = React.lazy(() => import('@components/Grid'));

const Container = styled.div(({ theme }) => ({
    minHeight: SHOW_HEADER ? `calc(100vh - ${theme.size.headerHeight})` : '100vh',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column'
}));

const EmptyRips = styled.div(({ theme }) => ({
    ...theme.typography.h4,
    marginTop: theme.spacing.xl,
    color: theme.colors.tertiaryLight
}));

function PackRips({ ual, match, history }) {
    const theme = useTheme();

    const [ packRips, setPackRips ] = useState(null);
    const [ packRipsData, setPackRipsData ] = useState([]);
    const [ errorMessage, setErrorMessage ] = useState('');

    function loadPackRips() {
        if (ual.activeUser) {
            getHistory(ual.activeUser.accountName, 1620921296500000, setPackRips);
        }
    }

    useEffect(() => {
        loadPackRips();
    }, []);

    useEffect(() => {
        if (packRips) {
            let assetsDataPromises = getAssetsData(
                packRips.map((pack) => pack.pack_asset_id)
            );
            Promise.all(assetsDataPromises).then((values) => {
                setPackRipsData(values);
            });
        }
    }, [packRips]);

    function viewRipAssets({ id }) {
        history(`${match.url}${ROUTES.view}/${id}`);
    }

    const ripsToRender = packRipsData
        ? packRipsData.map((pack) => {
            return {
                id: pack.asset_id,
                srcImg: IPFS_ENDPOINT + pack.data.img,
                badge: `#${
                    getAssetMintNumber(pack) === '0' ? '?' : getAssetMintNumber(pack)
                }`,
                caption: pack.data.name,
                onClick: viewRipAssets,
                buttons: [
                    {
                        label: STRINGS.viewRip
                    }
                ]
            };
        })
        : [];

    return (
        <Container>
            {packRips === null ? (
                <Loading />
            ) : (
                <>
                    <Suspense fallback={<Loading />}>
                        {packRipsData.length > 0 ? (
                            <Grid items={ripsToRender} />
                        ) : (
                            <EmptyRips>{STRINGS.noRips}</EmptyRips>
                        )}
                        <div style={{ margin: theme.spacing.s }}>
                            <StyledButton onClick={() => history(ROUTES.buypacks)}>
                                {STRINGS.buyMorePacks}
                            </StyledButton>
                        </div>
                        <StyledErrorModal
                            show={errorMessage !== ''}
                            onClose={() => setErrorMessage('')}
                        >
                            {errorMessage}
                        </StyledErrorModal>
                    </Suspense>
                </>
            )}
            {match && (
                <Route
                    path={match.url + ROUTES.view + '/:pack_id'}
                    render={(props) => (
                        <ViewAssetsFromRips {...props} packRips={packRips} />
                    )}
                />
            )}
        </Container>
    );
}

export default withUAL(WithRouter(PackRips));
