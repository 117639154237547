import styled from '@emotion/styled';
import React from 'react';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/zoom';

import { register } from 'swiper/element/bundle';
register();

const Video = styled.video(() => ({
    height: '100%',
    maxWidth: '100%'
}));

export default function ComicReader({ items }) {
    return (
        <swiper-container
            style={{
                '--swiper-navigation-color': '#FFF',
                height: '100vh',
                paddingBottom: '56px'
            }}
            zoom='true'
            keyboard='true'
            navigation='true'
            pagination={{
                type: 'fraction',
                clickable: true
            }}
            class='mySwiper'
        >
            {items.map((item, index) => (
                <swiper-slide key={index}>
                    <div className='swiper-zoom-container'>
                        {item.type === 'video' ? (
                            <Video muted autoPlay loop playsInline>
                                <source src={item.srcImg} type='video/mp4' />
                            </Video>
                        ) : (
                            <img src={item.srcImg} alt={`Slide ${index}`} />
                        )}
                    </div>
                </swiper-slide>
            ))}
        </swiper-container>
    );
}
