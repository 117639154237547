import WithRouter from '@components/WithRouter';
import styled from '@emotion/styled';

const CustomLink = styled.a(({ theme, header, tab, isActive }) => {
    let typography = header ? theme.typography.h5 : theme.typography.p;
    let headerStyle = header
        ? {
            marginLeft: 0,
            marginBottom: theme.spacing.s,
            [theme.mediaQuery.tabletLandscapeUp]: {
                marginLeft: theme.spacing.m,
                marginBottom: 0
            }
        }
        : {};
    let padding = tab
        ? {
            padding: `${theme.spacing.xs} ${theme.spacing.s}`
        }
        : {};

    return {
        ...typography,
        color: isActive() ? theme.colors.primaryDark : theme.colors.secondary,
        marginLeft: theme.spacing.s,
        borderBottom: isActive() ? `solid ${theme.colors.primaryDark} 2px` : 'none',
        textDecoration: 'none',
        opacity: 1,
        '&:hover': {
            color: theme.colors.primaryDark,
            cursor: 'pointer'
        },
        ...padding,
        ...headerStyle
    };
});

function StyledLink({
    href,
    children,
    header,
    tab,
    activeWhen,
    onClick,
    match,
    history,
    location,
    style
}) {
    function isActive() {
        if (href && location) {
            return location.pathname.includes(href.slice(2));
        }
    }

    return (
        <CustomLink
            href={href}
            header={header}
            tab={tab}
            isActive={isActive}
            onClick={onClick}
        >
            {children}
        </CustomLink>
    );
}

export default WithRouter(StyledLink);
