import styled from '@emotion/styled';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import Toggle from 'react-toggle';
import { withUAL } from 'ual-reactjs-renderer';

import {
    burnAsset,
    getAssetId,
    getAssetMintNumber,
    getInventory,
    getOpenedFromAsset,
    getSchemaFromAsset,
    getTemplateIdFromAsset,
    transferAsset
} from '@api/api';
import StyledButton from '@components/Button';
import StyledConfirmationModal from '@components/ConfirmationModal';
import StyledErrorModal from '@components/ErrorModal';
import Grid from '@components/Grid';
import Loading from '@components/Loading';
import OpenAnimation from '@components/OpenAnimation';
import theme from '@styles/theme';
import {
    BOX_TEMPLATE_ID,
    INVENTORY_UNPACK,
    IPFS_ENDPOINT,
    PACK_TEMPLATE_ID,
    SCHEMAS,
    SHOW_BUY_PAGE,
    SHOW_PACK_HISTORY
} from '@utils/globals';
import RES from '@utils/resources';
import ROUTES from '@utils/routes';
import STRINGS from '@utils/strings';
import { isCPUErrorMessage, isNETErrorMessage } from '@utils/utils';

import atomicHubLogo from '@images/atom-icon.png';

import '@styles/theme/index.css';
import 'react-toggle/style.css';

const InventoryNoticeContainer = styled.div(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: theme.spacing.l,
    color: theme.colors.secondaryLight
}));

const SecondaryMarketImage = styled.img(({ theme }) => ({
    margin: theme.spacing.xxs,
    width: 48,
    objectFit: 'contain'
}));

const ConfirmationModalDisclaimer = styled.p(({ theme }) => ({
    marginBottom: theme.spacing.m
}));

const CPUErrorMessage = styled.p(({ theme }) => ({
    ...theme.typography.p,
    margin: theme.spacing.s,
    textAlign: 'initial',
    a: {
        color: theme.colors.primary,
        '&:hover': {
            color: theme.colors.primaryDark
        }
    }
}));

const PacksOnlyToggle = styled.label(({ theme }) => ({
    ...theme.typography.h6,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    margin: `0 0 ${theme.spacing.s} 0`,
    '.class-name.react-toggle--checked .react-toggle-track': {
        backgroundColor: theme.colors.primary
    },
    '.class-name.react-toggle .react-toggle-track': {
        backgroundColor: theme.colors.secondaryLight
    },
    '.class-name .react-toggle-thumb': {
        border: 'none'
    }
}));

function Inventory({ ual }) {
    const history = useNavigate();

    const [ assets, setAssets ] = useState([]);
    const [ allAssets, setAllAssets ] = useState([]);
    const [ packs, setPacks ] = useState([]);
    const [ showPacksOnly, setShowPacksOnly ] = useState(false);
    const [ loading, setLoading ] = useState(true);
    const [ selectedAsset, setSelectedAsset ] = useState(null);
    const [ errorMessage, setErrorMessage ] = useState('');

    const [ showBurnConfirmation, setShowBurnConfirmation ] = useState(false);
    const [ showConfirmation, setShowConfirmation ] = useState(false);
    const [ showAnimation, setShowAnimation ] = useState(false);
    const [ selectedPackId, setSelectedPackId ] = useState(null);
    const [ selectedTemplateId, setSelectedTemplateId ] = useState(null);
    const [ selectedMintNumber, setSelectedMintNumber ] = useState(null);
    const [ selectedName, setSelectedName ] = useState(null);
    const [ showSuccess, setShowSuccess ] = useState(false);

    const paginationSize = 24;

    const [ paginationWindow, setPaginationWindow ] = useState({
        start: 0,
        end: paginationSize
    });

    function loadInventory() {
        if (ual.activeUser) {
            getInventory(
                ual.activeUser.accountName,
                (allAssets) => {
                    setAllAssets(allAssets);
                    setLoading(false);
                },
                (errorMessage) => setErrorMessage(errorMessage)
            );
        }
    }

    useEffect(() => {
        loadInventory();
    }, []);

    useEffect(() => {
        let filteredArray = allAssets.filter((asset) => {
            let assetSchemaName = getSchemaFromAsset(asset);
            let accum = SCHEMAS.includes(assetSchemaName);
            return accum;
        });

        setAssets(filteredArray);
    }, [allAssets]);

    useEffect(() => {
        let filteredArray = assets.filter((asset) => {
            let assetTemplateId = getTemplateIdFromAsset(asset);
            let accum = [ BOX_TEMPLATE_ID, PACK_TEMPLATE_ID ].includes(assetTemplateId);
            return accum && canOpenAsset(asset);
        });
        setPacks(filteredArray);
    }, [assets]);

    const changePage = (pageNumber) => {
        setPaginationWindow({
            start: (pageNumber - 1) * paginationSize,
            end: Math.min(pageNumber * paginationSize, assets.length)
        });
    };

    useEffect(() => {
        setPaginationWindow({
            start: 0,
            end: Math.min(paginationSize, assets.length)
        });
    }, [assets]);

    function openPack(packToOpen) {
        setSelectedPackId(packToOpen.id);
        setSelectedMintNumber(packToOpen.badge);
        setSelectedTemplateId(packToOpen.templateId);
        setSelectedName(packToOpen.caption);
        setShowConfirmation(true);
    }

    function onOpenPackConfirmation() {
        setShowConfirmation(false);
        transferAsset(
            ual.activeUser,
            selectedPackId,
            () => {
                setShowAnimation(true);
            },
            () => setErrorMessage(STRINGS.defaultErrorMessage)
        );
    }

    function canOpenAsset(asset) {
        return getOpenedFromAsset(asset) === 0;
    }

    const burnAssetConfirm = () => {
        const onBurnCallback = () => {
            setLoading(true);
            setAssets([]);
            setTimeout(loadInventory, 500);
            setSelectedAsset(null);
            setShowBurnConfirmation(false);
        };
        const onError = (errorMessage) => {
            setErrorMessage(errorMessage);
            setShowBurnConfirmation(false);
        };
        burnAsset(
            ual.activeUser,
            getAssetId(selectedAsset),
            onBurnCallback,
            onError
        );
    };

    const onBurnAsset = (asset) => {
        setSelectedAsset(assets.filter((a) => getAssetId(a) === asset.id)[0]);
        setShowBurnConfirmation(true);
    };

    const handleShowPacksOnlyChange = () => {
        setShowPacksOnly(!showPacksOnly);
    };

    const getConfirmationModalTitle = () => {
        let title = '';
        if (selectedAsset != null) {
            if (
                (getTemplateIdFromAsset(selectedAsset) === PACK_TEMPLATE_ID ||
          getTemplateIdFromAsset(selectedAsset) === BOX_TEMPLATE_ID) &&
        selectedAsset.data.opened === 0
            ) {
                title = STRINGS.openPackConfirmationTitle
                    .replace('{0}', selectedAsset.data.name)
                    .replace('{1}', getAssetMintNumber(selectedAsset));
            } else {
                title = STRINGS.burnConfirmationTitle
                    .replace('{0}', selectedAsset.data.name)
                    .replace('{1}', getAssetMintNumber(selectedAsset));
            }
        }
        return title;
    };

    const itemsToShow = (list) =>
        list
            ? list
                .slice(paginationWindow.start, paginationWindow.end)
                .map((asset) => {
                    const isImage = asset.data.img != null;
                    return {
                        id: getAssetId(asset),
                        srcImg: `${IPFS_ENDPOINT}${
                            isImage ? asset.data.img : asset.data.video
                        }`,
                        badge: `#${
                            getAssetMintNumber(asset) === '0'
                                ? '?'
                                : getAssetMintNumber(asset)
                        }`,
                        caption: asset.data.name,
                        templateId: getTemplateIdFromAsset(asset),
                        type: isImage ? 'image' : 'video',
                        srcBackImg: asset.data.backimg
                            ? `${IPFS_ENDPOINT}${asset.data.backimg}`
                            : null,
                        srcBackImg2: asset.data.backimg2
                            ? `${IPFS_ENDPOINT}${asset.data.backimg2}`
                            : null,
                        srcBackImg3: asset.data.backimg3
                            ? `${IPFS_ENDPOINT}${asset.data.backimg3}`
                            : null,
                        buttons:
                INVENTORY_UNPACK && canOpenAsset(asset)
                    ? [
                        {
                            label: STRINGS.viewAsset,
                            secondary: true
                        },
                        {
                            label: STRINGS.openPack,
                            onClick: openPack
                        }
                    ]
                    : [
                        {
                            label: STRINGS.viewAsset
                        }
                    ]

                        // {
                        //     label: (getTemplateIdFromAsset(asset) === PACK_TEMPLATE_ID || getTemplateIdFromAsset(asset) === BOX_TEMPLATE_ID) && asset.data.opened === 0 ? STRINGS.openAndBurn : STRINGS.burn,
                        //     onClick: onBurnAsset
                        // }
                    };
                })
            : [];

    const handleSeeMore = (items) => {
        setPaginationWindow((prev) => ({
            ...prev,
            end: Math.min(prev.end + paginationSize, items.length)
        }));
    };

    return (
        <div style={{ textAlign: 'center' }}>
            {loading ? (
                <Loading />
            ) : (
                <>
                    <InventoryNoticeContainer>
                        <h4>{STRINGS.viewCollection}</h4>
                        <a
                            href={RES.secondaryMarketLinks.atomicHubInventory.link.replace(
                                '{0}',
                                ual.activeUser.accountName
                            )}
                            target='_blank'
                            rel='noreferrer noopener'
                        >
                            <SecondaryMarketImage
                                src={atomicHubLogo}
                                alt={RES.secondaryMarketLinks.atomicHubInventory.label}
                            />
                        </a>
                    </InventoryNoticeContainer>
                    <PacksOnlyToggle>
                        <span style={{ marginBottom: 5 }}>
                            {showPacksOnly ? 'Packs only' : 'All assets'}
                        </span>
                        <Toggle
                            defaultChecked={showPacksOnly}
                            className='class-name'
                            onChange={handleShowPacksOnlyChange}
                            icons={false}
                        />
                    </PacksOnlyToggle>
                    {showPacksOnly ? (
                        <>
                            <Grid canExpand items={itemsToShow(packs)} />
                            {paginationWindow.end < packs.length && (
                                <StyledButton secondary onClick={handleSeeMore(packs)}>
                  See More
                                </StyledButton>
                            )}
                        </>
                    ) : (
                        <>
                            <Grid canExpand items={itemsToShow(assets)} />
                            {paginationWindow.end < assets.length && (
                                <StyledButton secondary onClick={handleSeeMore(assets)}>
                  See More
                                </StyledButton>
                            )}
                        </>
                    )}
                    <div
                        style={{
                            padding: theme.spacing.xl,
                            gap: theme.spacing.s,
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: 'column'
                        }}
                    >
                        <a
                            href={RES.secondaryMarketLinks.atomicHubEntireCollection.link.replace(
                                '{0}',
                                ual.activeUser.accountName
                            )}
                            target='_blank'
                            rel='noreferrer noopener'
                        >
                            <StyledButton secondary>
                                {STRINGS.viewEntireCollection}
                            </StyledButton>
                        </a>
                    </div>
                    {SHOW_BUY_PAGE && (
                        <div style={{ margin: theme.spacing.s }}>
                            <StyledButton onClick={() => history(ROUTES.buypacks)}>
                                {STRINGS.buyMorePacks}
                            </StyledButton>
                        </div>
                    )}
                    <StyledErrorModal
                        show={errorMessage !== ''}
                        onClose={() => setErrorMessage('')}
                    >
                        {errorMessage}
                        {isCPUErrorMessage(errorMessage) ? (
                            <CPUErrorMessage>{STRINGS.cpuErrorMessage}</CPUErrorMessage>
                        ) :
                            ''
                        }
                        {isNETErrorMessage(errorMessage) ? (
                            <CPUErrorMessage>{STRINGS.netErrorMessage}</CPUErrorMessage>
                        ) :
                            ''
                        }
                    </StyledErrorModal>
                    <StyledConfirmationModal
                        show={showBurnConfirmation}
                        onConfirm={burnAssetConfirm}
                        onClose={() => setShowConfirmation(false)}
                        confirmationButtonLabel={
                            selectedAsset &&
              (getTemplateIdFromAsset(selectedAsset) === PACK_TEMPLATE_ID ||
                getTemplateIdFromAsset(selectedAsset) === BOX_TEMPLATE_ID) &&
              selectedAsset.data.opened === 0
                                ? STRINGS.openAndBurn
                                : STRINGS.burn
                        }
                        title={getConfirmationModalTitle()}
                    >
                        <ConfirmationModalDisclaimer>
                            {STRINGS.burnConfirmationWarning}
                        </ConfirmationModalDisclaimer>
                    </StyledConfirmationModal>
                    {INVENTORY_UNPACK ? (
                        <>
                            <StyledConfirmationModal
                                show={showConfirmation}
                                onConfirm={onOpenPackConfirmation}
                                onClose={() => setShowConfirmation(false)}
                                confirmationButtonLabel={STRINGS.openPack}
                                title={STRINGS.openPackConfirmation
                                    .replace('{0}', selectedName)
                                    .replace(
                                        '{1}',
                                        selectedMintNumber === '0' ? `? ` : selectedMintNumber
                                    )}
                            />
                            <OpenAnimation
                                showAnimation={showAnimation}
                                onAnimationEnd={() => {
                                    setTimeout(() => {
                                        setShowAnimation(false);
                                        SHOW_PACK_HISTORY
                                            ? history(
                                                `${ROUTES.packrips}${ROUTES.view}/${selectedPackId}`
                                            )
                                            : history(`${ROUTES.inventory}`);
                                    }, 2000);
                                }}
                                templateId={selectedTemplateId}
                            />
                            <StyledConfirmationModal
                                show={showSuccess}
                                onConfirm={() => history(ROUTES.packrips)}
                                onClose={() => {
                                    setShowSuccess(false);
                                    loadInventory();
                                }}
                                confirmationButtonLabel={STRINGS.goToPackRips}
                                cancelButtonLabel={STRINGS.close}
                                title={STRINGS.openPackSuccess}
                            />
                        </>
                    ) : null}
                </>
            )}
        </div>
    );
}

export default withUAL(Inventory);
