import styled from '@emotion/styled';
import { Route, Routes } from 'react-router-dom';
import { withUAL } from 'ual-reactjs-renderer';

import BuyPacks from '@context/BuyPacks/BuyPacks';
import FAQ from '@context/FAQ';
import Home from '@context/Home/Home';
import Inventory from '@context/Inventory';
import NotFound from '@context/NotFound';
import PackRips from '@context/PackRips';
import Reader from '@context/Reader';
import {
    SHOW_BUY_PAGE,
    SHOW_FAQ,
    SHOW_HEADER,
    SHOW_PACK_HISTORY,
    WAX_SIGN_IN
} from '@utils/globals';
import ROUTES from '@utils/routes';

import WithRouter from '@components/WithRouter';

const Container = styled.div(({ theme }) => ({
    width: '100%',
    minHeight: `calc(100vh - ${theme.size.headerHeight} - ${theme.size.footerHeight})`,
    margin: SHOW_HEADER ? `${theme.size.headerHeight} 0px 0px 0px` : 0
}));

function Content({ ual, location, history }) {
    if (WAX_SIGN_IN && ual && !ual.activeUser) {
        if (
            location &&
      (location.pathname.includes(ROUTES.inventory) ||
        location.pathname.includes(ROUTES.reader) ||
        location.pathname.includes(ROUTES.packrips))
        ) {
            history(ROUTES.home);
        }
    }

    return (
        <Container>
            <Routes>
                <Route path='/' element={<Home />} />
                <Route path={ROUTES.home} element={<Home />} />
                {SHOW_BUY_PAGE ? (
                    <Route path={ROUTES.buypacks} element={<BuyPacks />} />
                ) : null}
                {SHOW_FAQ ? <Route path={ROUTES.faq} element={<FAQ />} /> : null}
                {WAX_SIGN_IN ? (
                    <>
                        <Route path={ROUTES.reader} element={<Reader />} />
                        <Route path={ROUTES.inventory} element={<Inventory />} />
                        {SHOW_PACK_HISTORY ? (
                            <Route path={ROUTES.packrips} element={<PackRips />} />
                        ) : null}
                    </>
                ) : null}
                <Route path={ROUTES.notfound} element={<NotFound />} />
                <Route path={ROUTES.notfound} />
            </Routes>
        </Container>
    );
}

export default WAX_SIGN_IN ? withUAL(WithRouter(Content)) : WithRouter(Content);
